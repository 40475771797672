import { useContext, computed, useRouter } from "@nuxtjs/composition-api";
import { useLogout } from "~/components/_refactored/auth";
import { useUserStore } from "~/stores/user";
import { useSettingsStore } from "~/stores/settings";

export function useUserMenu() {
  const userStore = useUserStore();
  const { i18n, localePath, store, $helpers } = useContext();
  const router = useRouter();
  const logout = useLogout();

  const settingsStore = useSettingsStore();
  const favoriteCount = store.getters["localStorage/likedPersonCount"];

  const userMenu = computed(() => {
    if (userStore.meLoading) {
      return [
        {
          id: "login-spinner",
          text: i18n.t("navbar.loggingIn"),
          icon: "spinner",
        },
      ];
    }

    if (!userStore.me) {
      return [
        {
          id: "register-professional",
          to: localePath({ name: "registrationPage" }),
          text: i18n.t("navbar.registerAsProfessional"),
          icon: "user-plus",
        },
        {
          id: "login",
          to: localePath({ name: "login" }),
          text: i18n.t("navbar.login"),
          icon: "sign-in-alt",
        },
      ];
    }

    const items = [];

    if (userStore.me.isAdmin) {
      items.push({
        id: "conductor",
        to: localePath({ name: "conductorIndexPage" }),
        text: i18n.t("navbar.user.conductor"),
        icon: "magic",
      });
      items.push({
        to: localePath({
          name: "professionalPage",
          params: { professionalSeoName: userStore.me.seoName },
        }),
        text: i18n.t("navbar.user.profile"),
        icon: "user",
      });
    }

    if (userStore.me.isMate) {
      const mateItems = [
        {
          id: "buyTenders",
          to: localePath({ name: "buyTendersPage" }),
          text: i18n.t("navbar.user.buyTenders"),
          icon: "gas-pump",
        },
        {
          id: "boughtTenders",
          to: localePath({ name: "boughtTendersPage" }),
          text: i18n.t("navbar.user.boughtTenders"),
          icon: "tasks",
        },
        {
          id: "closedTenders",
          to: localePath({ name: "closedTendersPage" }),
          text: i18n.t("navbar.user.closedTenders"),
          icon: "ban",
        },
        {
          id: "addPrices",
          to: localePath({ name: "addPricesPage" }),
          text: i18n.t("navbar.user.addPrices"),
          icon: "dollar-sign",
        },
        {
          id: "subscription",
          to: localePath({ name: "subscriptionPage" }),
          text: i18n.t("navbar.user.subscription"),
          icon: "rocket",
        },
      ];

      // If user has no professions and no mini markets, do not show the BuyTenders page
      if (
        !userStore.me.professions?.length &&
        !userStore.me.miniMarkets?.length
      ) {
        mateItems.splice(0, 1);
      }

      items.push(...mateItems);
    }

    if (!userStore.me.isMate) {
      items.push({
        id: "registerAsProfessional",
        to: localePath({ name: "registrationPage" }),
        text: i18n.t("navbar.user.registerAsProfessional"),
        icon: "crown",
      });
    }

    if (!settingsStore.isFavoritesDisabled) {
      items.push({
        id: "favorites",
        to: localePath({ name: "favorites" }),
        text: i18n.t("navbar.user.favorites", { count: favoriteCount }),
        icon: "heart",
      });
    }

    items.push(
      {
        id: "myTenders",
        to: localePath({ name: "myTenders" }),
        text: i18n.t("navbar.user.myTenders"),
        icon: "couch",
      },
      {
        id: "settings",
        to: localePath({ name: "settingsPage" }),
        text: i18n.t("navbar.user.settings"),
        icon: "cog",
      },
      {
        id: "back-to-index",
        to: "/",
        text: i18n.t("navbar.user.backToMainSite"),
        icon: "angle-double-left",
      },
      {
        id: "logout",
        click: () => {
          logout();
          router.push("/");
        },
        text: i18n.t("navbar.user.logout"),
        icon: "sign-out-alt",
      }
    );

    return [
      {
        id: "user-menu",
        text: userStore.me.name,
        image: $helpers.getProfilePictureThumbnailUrl(userStore.me.id),
        isLast: true,
        items,
      },
    ];
  });

  return {
    userMenu,
  };
}
